import React, { useEffect, useRef, useState } from "react";
import classes from "./First.module.scss";
import gsap from "gsap";
import bg from '../../../assets/img/about/1/1.png';
import bg_mob from '../../../assets/img/about/1/11.png';
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const r1 = useRef(null);
  const r2 = useRef(null);
  const r3 = useRef(null);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(r1.current, {
        top: 0,

        opacity: 1,
        delay: 0.5,
      });
      gsap.timeline().to(r2.current, {
        top: 0,

        opacity: 1,
        delay: 0.8,
      });
      gsap.timeline().to(r3.current, {
        top: 0,

        opacity: 1,
        delay: 1,
      });
    }
  }, [width]);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <img 
          className={classes.bannerBg}
          src={width > 480 ? bg : bg_mob}
          alt=""
        />
        <div className="container">
          <h2 className={[classes.firstTitle, "font-48"].join(" ")} ref={r1}>
            {t('about.banner.title')}
          </h2>
          <p className={[classes.firstText, "font-20"].join(" ")} ref={r2}>
            {t('about.banner.text_01')}
          </p>
          <p className={[classes.firstText, "font-20"].join(" ")} ref={r3}>
            {t('about.banner.text_02')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default First;
