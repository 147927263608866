import Button from "../../GlobalComponents/Button/Button";
import React, { useEffect, useRef } from "react";
import classes from './GetDeposit.module.scss';
import card_01 from '../../../assets/img/BonusesPage/GetDeposit/card_01.png';
import card_02 from '../../../assets/img/BonusesPage/GetDeposit/card_02.png';
import card_03 from '../../../assets/img/BonusesPage/GetDeposit/card_03.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const GetDeposit = () => {
    const { t } = useTranslation();
    const getDepositCardRef_01 = useRef(null);
    const getDepositCardRef_02 = useRef(null);
    const getDepositCardRef_03 = useRef(null);
    const getDepositCardsRef = useRef(null);
    const cards = [
        {
            ref: getDepositCardRef_01,
            image: card_01,
            text: 'bonuses.deposit.card_01.text',
        },
        {
            ref: getDepositCardRef_02,
            image: card_02,
            text: 'bonuses.deposit.card_02.text',
        },
        {
            ref: getDepositCardRef_03,
            image: card_03,
            text: 'bonuses.deposit.card_03.text',
        },
    ];
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(getDepositCardRef_01.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: getDepositCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(getDepositCardRef_02.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: getDepositCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(getDepositCardRef_03.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: getDepositCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
            }
        }
    }, []);
    return (
        <section>
            <div className={classes.getDeposit}>
                <div className="container">
                    <div className={classes.content}>
                        <div className={classes.left}>
                            <h2 className={[classes.title, 'font-48'].join(' ')}>
                                {t('bonuses.deposit.title')}
                            </h2>
                            <h3 className={classes.subtitle}>
                                {t('bonuses.deposit.subtitle')}
                            </h3>
                            <div className={classes.btn}>
                                <Button>
                                    {t('btn.get_bonus')}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.right}>
                            <p className="font-20">
                                {t('bonuses.deposit.text')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.btn2}>
                        <Button>
                            {t('btn.get_bonus')}
                        </Button>
                    </div>
                </div>
                <div ref={getDepositCardsRef} className={classes.cards}>
                    <Swiper
                        modules={[Pagination]}
                        className="getDepositSwiper"
                        spaceBetween={20}
                        slidesPerView={1}
                        pagination={true}
                        breakpoints={{
                            850: {
                                slidesPerView: 3,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide ref={card.ref} className="getDepositSwiperSlide" key={index}>
                                <div className={classes.card}>
                                    <img className={classes.cardImage} src={card.image} alt='' />
                                    <p className={classes.cardText}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default GetDeposit;
