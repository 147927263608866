import React from "react";
import { Helmet } from "react-helmet-async";
import First from "../../components/AboutComponents/First/First";
import BecomeBetter from "../../components/HomePage/BecomeBetter/BecomeBetter";
import Second from "../../components/AboutComponents/Second/Second";
import Three from "../../components/AboutComponents/Three/Three";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Docs from "../../components/AboutComponents/Docs/Docs";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>GMG (Dubai) Limited · About Us</title>
        <meta name="title" content="GMG (Dubai) Limited - Learn More About Our Vision and Commitment to Trading Excellence" />
        <meta property="og:title" content="GMG (Dubai) Limited - Learn More About Our Vision and Commitment to Trading Excellence" />
        <meta property="twitter:title" content="GMG (Dubai) Limited - Learn More About Our Vision and Commitment to Trading Excellence" />

        <meta name="description" content="Discover the story behind GMG (Dubai) Limited and our mission to empower traders around the world. Our About Us page provides an in-depth look at our team, values, and services, as well as our commitment to providing the highest levels of transparency, innovation, and customer support." />
        <meta property="og:description" content="Discover the story behind GMG (Dubai) Limited and our mission to empower traders around the world. Our About Us page provides an in-depth look at our team, values, and services, as well as our commitment to providing the highest levels of transparency, innovation, and customer support." />
        <meta property="twitter:description" content="Discover the story behind GMG (Dubai) Limited and our mission to empower traders around the world. Our About Us page provides an in-depth look at our team, values, and services, as well as our commitment to providing the highest levels of transparency, innovation, and customer support." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Docs />
        <Second />
        <Three />
        <BecomeBetter />
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
