import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import gsap from "gsap";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/about/2/1.png";
import img2 from "../../../assets/img/about/2/2.png";
import img3 from "../../../assets/img/about/2/3.png";
import img4 from "../../../assets/img/about/2/4.png";
import img5 from "../../../assets/img/about/2/5.png";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Second = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const [width, setWidth] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const mainRef = useRef(null);
  const r1 = useRef(null);
  const r2 = useRef(null);
  const r3 = useRef(null);
  const r4 = useRef(null);
  const r5 = useRef(null);
  const r6 = useRef(null);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(r1.current, {
        top: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });

      gsap.timeline().to(r2.current, {
        top: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(r3.current, {
        top: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(r4.current, {
        top: 0,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 7,
        },
      });
      gsap.timeline().to(r5.current, {
        top: 0,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 7,
        },
      });
      gsap.timeline().to(r6.current, {
        top: 0,
        duration: 2,
        delay: 2,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 7,
        },
      });
    }
  }, [width]);
  return (
    <div className={[classes.second, ""].join(" ")} ref={mainRef}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div
              className={[
                classes.secondRowColumn,
                classes.secondRowFirstColumn,
                "",
              ].join(" ")}
              ref={r1}
            >
              <h2
                className={[classes.secondRowFirstColumnTittle, "font-48"].join(
                  " "
                )}
              >
                {t('about.uniq.title')}
              </h2>
              <div
                className={[classes.secondRowFirstColumnText, "font-20"].join(
                  " "
                )}
              >
                {t('about.uniq.text')}
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")} ref={r2}>
              <div className={[classes.secondRowColumnItem, ""].join(" ")}>
                <div className={[classes.secondRowColumnItemImg, ""].join(" ")}>
                  <img src={img1} alt=''/>
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-32"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_01.title')}
                </div>
                <div
                  className={[classes.secondRowColumnItemText, "font-20"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_01.text')}
                </div>
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")} ref={r3}>
              <div className={[classes.secondRowColumnItem, ""].join(" ")}>
                <div className={[classes.secondRowColumnItemImg, ""].join(" ")}>
                  <img src={img2} alt=''/>
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-32"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_02.title')}
                </div>
                <div
                  className={[classes.secondRowColumnItemText, "font-20"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_02.text')}
                </div>
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")} ref={r4}>
              <div className={[classes.secondRowColumnItem, ""].join(" ")}>
                <div className={[classes.secondRowColumnItemImg, ""].join(" ")}>
                  <img src={img3} alt=''/>
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-32"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_03.title')}
                </div>
                <div
                  className={[classes.secondRowColumnItemText, "font-20"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_03.text')}
                </div>
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")} ref={r5}>
              <div className={[classes.secondRowColumnItem, ""].join(" ")}>
                <div className={[classes.secondRowColumnItemImg, ""].join(" ")}>
                  <img src={img4} alt=''/>
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-32"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_04.title')}
                </div>
                <div
                  className={[classes.secondRowColumnItemText, "font-20"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_04.text')}
                </div>
              </div>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")} ref={r6}>
              <div className={[classes.secondRowColumnItem, ""].join(" ")}>
                <div className={[classes.secondRowColumnItemImg, ""].join(" ")}>
                  <img src={img5} alt=''/>
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-32"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_05.title')}
                </div>
                <div
                  className={[classes.secondRowColumnItemText, "font-20"].join(
                    " "
                  )}
                >
                  {t('about.uniq.card_05.text')}
                </div>
              </div>
            </div>
          </div>
          <div className={[classes.secondMob, ""].join(" ")}>
            <div className="container">
              <div className={[classes.secondBottomSlider, ""].join(" ")}>
                <Swiper
                  modules={[Pagination, Navigation]}
                  spaceBetween={20}
                  slidesPerView={3}
                  pagination={{ clickable: true }}
                  onInit={(ev) => {
                    set_my_swiper(ev);
                  }}
                  breakpoints={{
                    1100: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    100: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className={[classes.secondRowColumn, ""].join(" ")}>
                      <div
                        className={[classes.secondRowColumnItem, ""].join(" ")}
                      >
                        <div
                          className={[classes.secondRowColumnItemImg, ""].join(
                            " "
                          )}
                        >
                          <img src={img1} alt=''/>
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_01.title')}
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_01.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={[classes.secondRowColumn, ""].join(" ")}>
                      <div
                        className={[classes.secondRowColumnItem, ""].join(" ")}
                      >
                        <div
                          className={[classes.secondRowColumnItemImg, ""].join(
                            " "
                          )}
                        >
                          <img src={img2} alt=''/>
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_02.title')}
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_02.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={[classes.secondRowColumn, ""].join(" ")}>
                      <div
                        className={[classes.secondRowColumnItem, ""].join(" ")}
                      >
                        <div
                          className={[classes.secondRowColumnItemImg, ""].join(
                            " "
                          )}
                        >
                          <img src={img3} alt=''/>
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_03.title')}
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_03.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={[classes.secondRowColumn, ""].join(" ")}>
                      <div
                        className={[classes.secondRowColumnItem, ""].join(" ")}
                      >
                        <div
                          className={[classes.secondRowColumnItemImg, ""].join(
                            " "
                          )}
                        >
                          <img src={img4} alt=''/>
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_04.title')}
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_04.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={[classes.secondRowColumn, ""].join(" ")}>
                      <div
                        className={[classes.secondRowColumnItem, ""].join(" ")}
                      >
                        <div
                          className={[classes.secondRowColumnItemImg, ""].join(
                            " "
                          )}
                        >
                          <img src={img5} alt=''/>
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_05.title')}
                        </div>
                        <div
                          className={[
                            classes.secondRowColumnItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('about.uniq.card_05.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className={[classes.firstBottomLeftArrows, ""].join(" ")}>
                <div
                  className={[classes.firstBottomLeftArrowsLeft, ""].join(" ")}
                  onClick={() => {
                    my_swiper.slidePrev();
                  }}
                ></div>
                <div
                  className={[classes.firstBottomLeftArrowsRight, ""].join(" ")}
                  onClick={() => {
                    my_swiper.slideNext();
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
