import React, { useEffect, useRef, useState } from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/about/3/1.png";
import img2 from "../../../assets/img/about/3/2.png";
import img3 from "../../../assets/img/about/3/3.png";
import img4 from "../../../assets/img/about/3/4.png";
import img5 from "../../../assets/img/about/3/5.png";
import img6 from "../../../assets/img/about/3/6.png";
import mob from "../../../assets/img/about/3/bg.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Three = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const mainRef = useRef(null);
  const r1 = useRef(null);
  const r2 = useRef(null);
  const r3 = useRef(null);
  const r4 = useRef(null);
  const r5 = useRef(null);
  const r6 = useRef(null);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(r1.current, {
        top: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });

      gsap.timeline().to(r2.current, {
        top: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(r3.current, {
        top: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(r4.current, {
        top: 0,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(r5.current, {
        top: 0,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(r6.current, {
        top: 0,
        duration: 2,
        delay: 2,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
    }
  }, [width]);
  return (
    <div className={[classes.three, ""].join(" ")} ref={mainRef}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <h2 className={[classes.threeRowLeftTitle, "font-48"].join(" ")}>
                {t('about.value.title')}
              </h2>
              <div className={[classes.threeRowLeftIcons, ""].join(" ")}>
                <div
                  className={[classes.threeRowLeftItem, ""].join(" ")}
                  ref={r1}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img1} alt='' />
                  </div>
                  <div
                    className={[classes.threeRowLeftItemText, "font-24"].join(
                      " "
                    )}
                  >
                    {t('about.value.li_01')}
                  </div>
                </div>
                <div
                  className={[classes.threeRowLeftItem, ""].join(" ")}
                  ref={r2}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img2} alt='' />
                  </div>
                  <div
                    className={[classes.threeRowLeftItemText, "font-24"].join(
                      " "
                    )}
                  >
                    {t('about.value.li_02')}
                  </div>
                </div>
                <div
                  className={[classes.threeRowLeftItem, ""].join(" ")}
                  ref={r3}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img3} alt='' />
                  </div>
                  <div
                    className={[classes.threeRowLeftItemText, "font-24"].join(
                      " "
                    )}
                  >
                    {t('about.value.li_03')}
                  </div>
                </div>
                <div
                  className={[classes.threeRowLeftItem, ""].join(" ")}
                  ref={r4}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img4} alt='' />
                  </div>
                  <div
                    className={[classes.threeRowLeftItemText, "font-24"].join(
                      " "
                    )}
                  >
                    {t('about.value.li_04')}
                  </div>
                </div>
                <div
                  className={[classes.threeRowLeftItem, ""].join(" ")}
                  ref={r5}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img5} alt='' />
                  </div>
                  <div
                    className={[classes.threeRowLeftItemText, "font-24"].join(
                      " "
                    )}
                  >
                    {t('about.value.li_05')}
                  </div>
                </div>
                <div
                  className={[classes.threeRowLeftItem, ""].join(" ")}
                  ref={r6}
                >
                  <div className={[classes.threeRowLeftItemImg, ""].join(" ")}>
                    <img src={img6} alt='' />
                  </div>
                  <div
                    className={[classes.threeRowLeftItemText, "font-24"].join(
                      " "
                    )}
                  >
                    {t('about.value.li_06')}
                  </div>
                </div>
              </div>
              <div className={[classes.mobImg, ""].join(" ")}>
                <img src={mob} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
