import React, { useEffect, useRef, useState } from "react";
import classes from './BecomeBetter.module.scss';
import icon_01 from '../../../assets/img/MainPage/BecomeBetter/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/BecomeBetter/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/BecomeBetter/icon_03.png';
import arrow from '../../../assets/img/MainPage/BecomeBetter/arrow.svg';
import { Trans, useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const cards = [
    {
        id: 1,
        icon: icon_01,
        title: 'home.become.card_01.title',
        text: 'home.become.card_01.text',
    },
    {
        id: 2,
        icon: icon_02,
        title: 'home.become.card_02.title',
        text: 'home.become.card_02.text',
    },
    {
        id: 3,
        icon: icon_03,
        title: 'home.become.card_03.title',
        text: 'home.become.card_03.text',
    },
];

const BecomeBetter = () => {
    const { t } = useTranslation();
    const [ domLoaded, setDomLoaded ] = useState(false);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    useEffect(() => {
        setDomLoaded(true);
    }, []);
    return (
        <>
            {domLoaded &&
                <section className={classes.becomeBetter}>
                    <div className={["container", classes.container].join(' ')}>
                        <div className={classes.becomeBetterBody}>
                            <div className={classes.titleWr}>
                                <h2 className={[classes.title, 'font-48'].join(' ')}>
                                    {t('home.become.title')}
                                </h2>
                            </div>
                            <div className={classes.swiperWr}>
                                <Swiper 
                                    modules={[Pagination, Navigation]}
                                    className="becomeBetterSwiper"
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current,
                                    }}
                                    breakpoints={{
                                        600: {
                                          slidesPerView: 2,
                                        },
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                   }}
                                >
                                    {cards.map((card) =>
                                        <SwiperSlide className="becomeBetterSwiperSlide" key={card.id}>
                                            <div className={classes.card}>
                                                <div className={classes.cardHeader}>
                                                    <img className={classes.cardIcon} src={card.icon} alt={card.title}/>
                                                    <p className={[classes.cardTitle, 'font-20'].join(' ')}>
                                                        {t(card.title)}
                                                    </p>
                                                </div>
                                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                                    <Trans  components={{b: <b></b>}}>
                                                        {t(card.text)}
                                                    </Trans>
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                                <div className={classes.pagination}>
                                    <img ref={prevRef} className={classes.pagPrev} src={arrow} alt=''/>
                                    <img ref={nextRef} className={classes.pagNext} src={arrow} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default BecomeBetter;
