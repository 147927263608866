import HomePage from '../pages/HomePage/HomePage';
import ToolsPage from '../pages/ToolsPage/ToolsPage';
import AccounsPage from '../pages/AccountsPage/AccountsPage';
import BonusesPage from '../pages/BonusesPage/BonusesPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import EducationPage from '../pages/EducationPage/EducationPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/tools', element: ToolsPage },
    { path: '/:lang/account-types', element: AccounsPage },
    { path: '/:lang/bonuses', element: BonusesPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
    { path: '/:lang/education', element: EducationPage },
];


export const headerRoutes = [
    { id: 1, path: 'tools', text: 'header.tools' },
    { id: 2, path: 'account-types', text: 'header.accounts' },
    { id: 3, path: 'bonuses', text: 'header.bonuses' },
    { id: 4, path: 'faq', text: 'header.faq' },
    { id: 5, path: 'about', text: 'header.about' },
    { id: 6, path: 'education', text: "education_title" },
];
