import Button from "../../GlobalComponents/Button/Button";
import React, { useEffect, useRef, useState } from "react";
import classes from './Banner.module.scss';
import bg from '../../../assets/img/AccountTypes/Banner/bg.png';
import bg_mob from '../../../assets/img/AccountTypes/Banner/bg_mob.png';

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const accountBannerBodyRef = useRef(null);
    const accountBannerBgRef = useRef(null);
    const [width, setWidth] = useState();
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", (event) => {
            setWidth(window.innerWidth);
        });
    }, []);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.fromTo(accountBannerBodyRef.current, {
                    y: 100,
                    opacity: 0,
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.fromTo(accountBannerBgRef.current, {
                    scale: 1.2,
                }, {
                    scale: 1,
                    duration: 1.5,
                    delay: 0,
                });
            }
        }
    }, []);
    return (
        <section className={classes.banner}>
            <img 
                className={classes.bg}
                src={width > 480 ? bg : bg_mob}
                ref={accountBannerBgRef}
                alt='' 
            />
            <div className="container">
                <div className={classes.bannerBody}>
                    <div ref={accountBannerBodyRef}>
                        <h1 className={[classes.title, 'font-64'].join(' ')}>
                            {t('accounts.banner.title')}
                        </h1>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t('accounts.banner.text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.start_trading')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
