import React, { useState } from "react";
import classes from "./Most.module.scss";
import { useTranslation } from "react-i18next";

const Most = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "faq.tab_01.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: "faq.tab_01.text",
        },
      ],
    },
    {
      id: 2,
      title: "faq.tab_02.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq.tab_02.text`,
        },
      ],
    },
    {
      id: 3,
      title: "faq.tab_03.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq.tab_03.text`,
        },
      ],
    },
    {
      id: 4,
      title: "faq.tab_04.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq.tab_04.text`,
        },
      ],
    },
    {
      id: 5,
      title: "faq.tab_05.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq.tab_05.text`,
        },
      ],
    },
    {
      id: 6,
      title: "faq.tab_06.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq.tab_06.text`,
        },
      ],
    },
    {
      id: 7,
      title: "faq.tab_07.title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq.tab_07.text`,
        },
      ],
    },
  ]);

  return (
    <div className={classes.most}>
      <div className={classes.mostBody}>
        <div className="container">
          <h2 className={[classes.mostTitle, "font-64"].join(" ")}>
            {t('faq.title')}
          </h2>
          {spoilers.map((i) => {
            return (
              <div key={i.id} className={classes.mostSpoiler}>
                <p
                  className={
                    i.active
                      ? [
                        classes.mostSpoilerTitle,
                        "font-32",
                        classes.active,
                      ].join(" ")
                      : [classes.mostSpoilerTitle, "font-32"].join(" ")
                  }
                  onClick={() => {
                    setSpoilers(
                      spoilers.map((curI) => {
                        if (curI.id === i.id) {
                          return { ...curI, active: !curI.active };
                        }
                        return { ...curI, active: false };
                      })
                    );
                  }}
                >
                  {t(i.title)}
                </p>
                {i.contents.map((subI) => {
                  return (
                    <div
                      key={subI.id}
                      className={
                        i.active
                          ? subI.isStart
                            ? [
                              classes.mostSpoilerContent,
                              classes.active,
                              classes.star,
                              "font-20",
                            ].join(" ")
                            : [
                              classes.mostSpoilerContent,
                              classes.active,
                              "font-20",
                            ].join(" ")
                          : classes.mostSpoilerContent
                      }
                    >
                      {t(subI.text)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Most;
