import React, { useEffect, useRef } from "react";
import classes from './WhatTypes.module.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import card_bg_01 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_01.png';
import card_bg_03 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_03.png';
import card_bg_04 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_04.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const WhatTypes = () => {
    const { t } = useTranslation();
    const whatTypeCardRef_01 = useRef(null);
    const whatTypeCardRef_02 = useRef(null);
    const whatTypeCardRef_03 = useRef(null);
    const whatTypeCardsRef = useRef(null);
    const cards = [
        {
            id: 1,
            ref: whatTypeCardRef_01,
            image: card_bg_01,
            desc: 'tools.choose.card_01.desc',
            title: 'Standard',
            list: [
                'tools.choose.card_01.li_01',
                'tools.choose.card_01.li_02',
            ],
        },
        {
            id: 2,
            ref: whatTypeCardRef_02,
            image: card_bg_03,
            desc: 'tools.choose.card_03.desc',
            title: 'ECN',
            list: [
                'tools.choose.card_03.li_01',
                'tools.choose.card_03.li_02',
            ],
        },
        {
            id: 3,
            ref: whatTypeCardRef_03,
            image: card_bg_04,
            desc: 'tools.choose.card_04.desc',
            title: 'Crypto',
            list: [
                'tools.choose.card_04.li_01',
                'tools.choose.card_04.li_02',
            ],
        },
    ];
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(whatTypeCardRef_01.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: whatTypeCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(whatTypeCardRef_02.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: whatTypeCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(whatTypeCardRef_03.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: whatTypeCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={classes.whatTypes}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('bonuses.types.title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('bonuses.types.text')}
                </p>
            </div>
            <div ref={whatTypeCardsRef} className={classes.swiperWr}>
                <Swiper 
                    modules={[Pagination]}
                    className="whatTypeSwiper"
                    spaceBetween={20}
                    slidesPerView={1}
                    pagination={true}
                    breakpoints={{
                        1220: {
                            slidesPerView: 3,
                        },
                        650: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {cards.map((card) =>
                        <SwiperSlide ref={card.ref} key={card.id} className="whatTypeSwiperSlide">
                            <div className={classes.card}>
                                <div className={classes.cardContent}>
                                    <div className={classes.cardDesc}>
                                        <span>
                                            {t(card.desc)}
                                        </span>
                                    </div>
                                    <h3 className={[classes.cardTitle, 'font-32'].join(' ')}>
                                        {t(card.title)}
                                    </h3>
                                    <ul className="font-20">
                                        {card.list.map((li, liIndex) =>
                                            <li key={liIndex}>
                                                {t(li)}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className={classes.cardBtn}>
                                    <a href={`${process.env.REACT_APP_CABINET_LINK}/signup`} className={[classes.btn, 'no-select'].join(' ')}>
                                        {t('btn.open_account')}
                                    </a>
                                </div>
                                <img className={classes.cardImg} src={card.image} alt=''/>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            <div className="container">
                <div className={classes.subtitle}>
                    <span className={classes.subtitleText}>
                        {t('bonuses.types.subtitle')}
                    </span>
                    <div className={classes.subtitleBtn}>
                        <a href={`${process.env.REACT_APP_CABINET_LINK}/signup`} className="no-select">
                            {t('btn.see_more')}
                        </a>
                    </div>
                </div>
                <p className={[classes.subtext, 'font-20'].join(' ')}>
                    {t('bonuses.types.subtext')}
                </p>
            </div>
        </section>
    );
};

export default WhatTypes;
