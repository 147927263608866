import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import check_true from '../../../assets/img/AccountTypes/icons/check_true.svg';
import check_false from '../../../assets/img/AccountTypes/icons/check_false.svg';
import "swiper/css";
import classes from './AccountTypes.module.scss';

const AccountTypes = () => {
    const { t } = useTranslation();

    const cards = [
        {
            title: 'Bronze',
            list: [
                t("trade_2"),
                t("trade_2222"),
                t("trade_3"),
                t("trade_4"),
                {
                    text: t("trade_5"),
                    icon: true,
                },
                t("trade_6"),
                {
                    text: t("trade_7"),
                    icon: false,
                },
                t("trade_8"),
                t("trade_9"),
                t("trade_10"),
                {
                    text: t("trade_11"),
                    icon: true,
                },
                {
                    text: t("trade_12"),
                    icon: true,
                },
                t("trade_1333"),
                t("trade_13"),
            ],
        },
        {
            title: 'Silver',
            list: [
                t("trade_15"),
                {
                    type: 'list',
                    title: t("trade_1555"),
                    list: [
                        t("trade_1556"),
                        t("trade_1557"),
                    ]
                },
                t("trade_16"),
                t("trade_17"),
                {
                    text: t("trade_18"),
                    icon: true,
                },
                t("trade_19"),
                {
                    text: t("trade_20"),
                    icon: false,
                },
                t("trade_21"),
                t("trade_22"),
                t("trade_23"),
                {
                    text: t("trade_24"),
                    icon: true,
                },
                {
                    text: t("trade_25"),
                    icon: true,
                },
                {
                    text: t("trade_2555"),
                    icon: true,
                },
                t("trade_26"),
            ],
        },
        {
            title: 'Gold',
            list: [
                t("trade_28"),
                {
                    type: 'list',
                    title: t("trade_2881"),
                    list: [
                        t("trade_2882"),
                        t("trade_1557"),
                        t("trade_2884"),
                    ]
                },
                t("trade_29"),
                t("trade_30"),
                {
                    text: t("trade_31"),
                    icon: true,
                },
                t("trade_32"),
                {
                    text: t("trade_33"),
                    icon: false,
                },
                t("trade_34"),
                t("trade_35"),
                t("trade_36"),
                {
                    text: t("trade_37"),
                    icon: true,
                },
                {
                    text: t("trade_38"),
                    icon: true,
                },
                {
                    text: t("trade_3888"),
                    icon: true,
                },
                t("trade_39"),
            ],
        },
        {
            title: 'Platinum',
            list: [
                t("trade_28"),
                t("trade_29"),
                t("trade_30"),
                {
                    type: 'list',
                    title: t("trade_2881"),
                    list: [
                        t("trade_2882"),
                        t("trade_1557"),
                        t("trade_2884"),
                        t("trade_2885"),
                    ]
                },
                {
                    text: t("trade_44"),
                    icon: true,
                },
                t("trade_32"),
                {
                    text: t("trade_46"),
                    icon: false,
                },
                t("trade_47"),
                t("trade_35"),
                t("trade_49"),
                {
                    text: t("trade_50"),
                    icon: true,
                },
                {
                    text: t("trade_51"),
                    icon: true,
                },
                {
                    text: t("trade_3888"),
                    icon: true,
                },
                t("trade_52"),
            ],
        },
        {
            title: 'Diamond',
            list: [
                t("trade_28"),
                t("trade_29"),
                t("trade_30"),
                {
                    type: 'list',
                    title: t("trade_2881"),
                    list: [
                        t("trade_2882"),
                        t("trade_1557"),
                        t("trade_2884"),
                        t("trade_2885"),
                        t("trade_2886"),
                    ]
                },
                {
                    text: t("trade_57"),
                    icon: true,
                },
                t("trade_32"),
                {
                    text: t("trade_59"),
                    icon: false,
                },
                t("trade_60"),
                t("trade_35"),
                t("trade_62"),
                {
                    text: t("trade_63"),
                    icon: true,
                },
                {
                    text: t("trade_64"),
                    icon: true,
                },
                {
                    text: t("trade_3888"),
                    icon: true,
                },
                t("trade_65"),
            ],
        },
    ];

    const [swiper, setSwiper] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [labelLeftWidth, setLabelLeftWidth] = useState(0);
    const [labelRightWidth, setLabelRightWidth] = useState(0);

    const slideTo = (index) => {
        if (document.documentElement.clientWidth > 991) {
            if (index !== 2) {
                swiper.slideTo(index - 1, 300);
            }
        } else {
            swiper.slideTo(index);
        }
    };

    const setLabelInitState = () => {
        const label = $('#accountTypesLabel');
        const btn_02 = $('#accountTypesButton_02').width();
        if (currentSlide <= 1) {
            label.css({
                'width': `${labelLeftWidth}`,
                '-webkit-transform': `translateX(0px)`
            });
        }
        if (currentSlide >= 2) {
            label.css({
                'width': `${labelRightWidth}`,
                '-webkit-transform': `translateX(${labelLeftWidth - btn_02}px)`
            });
        }
    };

    const getButtonsData = () => {
        const btn_00 = $('#accountTypesButton_00').width();
        const btn_01 = $('#accountTypesButton_01').width();
        const btn_02 = $('#accountTypesButton_02').width();
        const btn_03 = $('#accountTypesButton_03').width();
        const btn_04 = $('#accountTypesButton_04').width();
        const offset = ($('#accountTypesButtons').width() - btn_00 - btn_01 - btn_02 - btn_03 - btn_04) / 4;
        const leftWidth = btn_00 + btn_01 + btn_02 + offset * 2;
        const rightWidth = btn_02 + btn_03 + btn_04 + offset * 2;
        setLabelLeftWidth(leftWidth);
        setLabelRightWidth(rightWidth);
    };

    useEffect(() => {
        setLabelInitState();
    }, [labelLeftWidth, labelRightWidth, currentSlide]);

    useEffect(() => {
        getButtonsData();
    }, []);

    return (
        <section className={classes.accountTypesWrap}>
            <div className="container mt">
                <h2 className={`${classes.title} font-48`}>
                    {t('trade.title')}
                </h2>
                <div id="accountTypesButtons" className={`${classes.buttons} no-select`}>
                    {cards.map((card, index) =>
                        <div
                            key={index}
                            id={`accountTypesButton_0${index}`}
                            className={`
                                ${classes.button} 
                                ${(currentSlide <= 1 && index <= 2) ? classes.buttonActive : ''}
                                ${(currentSlide >= 2 && index >= 2) ? classes.buttonActive : ''}
                                ${currentSlide === index ? classes.buttonActiveMob : ''}
                            `}
                            onClick={() => slideTo(index)}
                        >
                            <span>
                                {card.title}
                            </span>
                        </div>
                    )}
                    <div id="accountTypesLabel" className={classes.label}></div>
                </div>
            </div>
            <div className={`${classes.swiperContainer} container`}>
                <div className={classes.swiperWrap}>
                    <Swiper
                        className="accountTypesSwiper"
                        spaceBetween={20}
                        slidesPerView={1}
                        slidesPerGroupSkip={3}
                        noSwiping={true}
                        allowTouchMove={true}
                        onInit={(e) => { setSwiper(e) }}
                        onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
                        breakpoints={{
                            991: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                                noSwiping: true,
                                allowTouchMove: false,
                            },
                            600: {
                                spaceBetween: 30,
                                slidesPerView: 1,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="accountTypesSwiperSlide">
                                <div className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <div className={classes.cardContentText}>
                                            <h4 className={`${classes.cardTitle} font-20`}>
                                                {card.title}
                                            </h4>
                                            <ul>
                                                {card.list.map((li, liIndex) =>
                                                    <li key={liIndex}>
                                                        {typeof (li) === 'string' ?
                                                            <p>
                                                                {li}
                                                            </p>
                                                            :
                                                            li.type === 'list' ?
                                                                <>
                                                                    <p>
                                                                        {li.title}
                                                                    </p>
                                                                    <div className={classes.textassets}>
                                                                        {li.list.map((secLink, secLinkIndex) =>
                                                                            <p key={secLinkIndex}>
                                                                                {secLink}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className={classes.liContent}>
                                                                    <p>
                                                                        {li.text}
                                                                    </p>
                                                                    <img src={li.icon ? check_true : check_false} alt='' />
                                                                </div>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className={classes.btn}>
                                            <Button>
                                                {t('btn.open_account')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default AccountTypes;
