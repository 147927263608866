import React from "react";
import { Helmet } from "react-helmet-async";
import Most from "../../components/FaqComponents/Most/Most";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';

const FaqPage = () => {
  return (
    <>
      <Helmet>
        <title>GMG (Dubai) Limited · FAQ</title>
        <meta name="title" content="GMG (Dubai) Limited - Frequently Asked Questions about Trading with Cryptocurrencies" />
        <meta property="og:title" content="GMG (Dubai) Limited - Frequently Asked Questions about Trading with Cryptocurrencies" />
        <meta property="twitter:title" content="GMG (Dubai) Limited - Frequently Asked Questions about Trading with Cryptocurrencies" />

        <meta name="description" content="Find answers to all your questions about trading with GMG (Dubai) Limited in our comprehensive FAQ section. We cover everything from account creation and verification to trading strategies and security measures. Browse our FAQ today and get the information you need to succeed in the world of cryptocurrency trading!" />
        <meta property="og:description" content="Find answers to all your questions about trading with GMG (Dubai) Limited in our comprehensive FAQ section. We cover everything from account creation and verification to trading strategies and security measures. Browse our FAQ today and get the information you need to succeed in the world of cryptocurrency trading!" />
        <meta property="twitter:description" content="Find answers to all your questions about trading with GMG (Dubai) Limited in our comprehensive FAQ section. We cover everything from account creation and verification to trading strategies and security measures. Browse our FAQ today and get the information you need to succeed in the world of cryptocurrency trading!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Most />
        <Footer />
      </div>
    </>
  );
};

export default FaqPage;
