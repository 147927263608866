import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import bg from '../../../assets/img/BonusesPage/Banner/bg.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const bonusesBannerBodyRef = useRef(null);
    const bonusesBannerBgRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.fromTo(bonusesBannerBodyRef.current, {
                    y: 100,
                    opacity: 0,
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.fromTo(bonusesBannerBgRef.current, {
                    scale: 1.2,
                }, {
                    scale: 1,
                    duration: 1.5,
                    delay: 0,
                });
            }
        }
    }, []);
    return (
        <section className={classes.banner}>
            <img 
                className={classes.bg}
                src={bg}
                ref={bonusesBannerBgRef}
                alt=''
            />
            <div className="container">
                <div ref={bonusesBannerBodyRef}>
                    <div className={classes.bannerBody}>
                        <h1 className={[classes.title, 'font-64'].join(' ')}>
                            {t('bonuses.banner.title')}
                        </h1>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t('bonuses.banner.text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
