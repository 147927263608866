import React from "react";
import classes from './Footer.module.scss';
import logo from '../../../assets/img/logo.svg';
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;
    return (
        <footer className="container">
            <div className={classes.footer}>
                <div className={classes.logo}>
                    <img src={logo} alt='' />
                </div>
                <div className={classes.links}>
                    <div className={classes.linksItem}>
                        <a href={`mailto:support@${hostname}`}>
                            support@{hostname}
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href="tel:+971588742150">
                            +971588742150
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        Legal Status: DIFC Company<br/>
                    </div>
                    <div className={classes.linksItem}>
                        DFSA Reference Number: F001134
                    </div>
                    <div className={classes.linksItem}>
                        <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                            {t('footer.docs.terms')}
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                            {t('footer.docs.privacy')}
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href="/docs/AML_POLICY.pdf" target="_blank">
                            {t('footer.docs.aml')}
                        </a>
                    </div>
                </div>
                <div className={classes.disclaimer}>
                    <p className={classes.disclaimerTitle}>
                        {t('footer.disclaimer.title')}
                    </p>
                    <div className={classes.disclaimerText}>
                        <p>
                            {t('footer.disclaimer.text_01')}
                        </p>
                        <p>
                            {t('footer.disclaimer.text_02')}
                        </p>
                    </div>
                </div>
                <div className={classes.rights}>
                    <p className={classes.rightsText}>
                        {t('footer.rights_01')}
                    </p>
                    <p className={classes.rightsRights}>
                        {t('footer.rights_02')}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
