import React, { useEffect, useRef } from "react";
import classes from './HowTo.module.scss';
import image from '../../../assets/img/BonusesPage/how_to.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const HowTo = () => {
    const { t } = useTranslation();
    const howToCardRef_01 = useRef(null);
    const howToCardRef_02 = useRef(null);
    const howToCardRef_03 = useRef(null);
    const howToCompRef = useRef(null);
    const hotToCardsRef = useRef(null);
    const hotToImageRef = useRef(null);
    const cards = [
        {
            id: 1,
            ref: howToCardRef_01,
            title: 'bonuses.how_to.card_01.title',
            text: 'bonuses.how_to.card_01.text',
        },
        {
            id: 2,
            ref: howToCardRef_02,
            title: 'bonuses.how_to.card_02.title',
            text: 'bonuses.how_to.card_02.text',
        },
        {
            id: 3,
            ref: howToCardRef_03,
            title: 'bonuses.how_to.card_03.title',
            text: 'bonuses.how_to.card_03.text',
        },
    ];
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(howToCardRef_01.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: hotToCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(howToCardRef_02.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: hotToCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(howToCardRef_03.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: hotToCardsRef.current,
                        end: "+=300px",
                        scrub: 2,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={classes.howTo}>
            <div ref={howToCompRef} className="container">
                <div className={classes.howToBody}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        {t('bonuses.how_to.title')}
                    </h2>
                    <div ref={hotToCardsRef} className={classes.cards}>
                        {cards.map((card) =>
                            <div ref={card.ref} key={card.id} className={classes.card}>
                                <div className={[classes.cardNum, 'font-32'].join(' ')}>
                                    0{card.id}
                                </div>
                                <h4 className={[classes.cardTitle, 'font-32'].join(' ')}>
                                    {t(card.title)}
                                </h4>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <img ref={hotToImageRef} className={classes.image} src={image} alt=''/>
        </section>
    );
};

export default HowTo;
