import React, { useEffect, useRef, useState } from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/tools/2/mob.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Second = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const mainRef = useRef(null);
  const phoneRef = useRef(null);
  const monetsRef = useRef(null);
  const cardsRef = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(phoneRef.current, {
        scale: 1,
        opacity: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(monetsRef.current, {
        scale: 1,
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(cardsRef.current, {
        scale: 1,
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
    }
  }, [width]);

  return (
    <div className={[classes.second, ""].join(" ")} ref={mainRef}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondWrapper, ""].join(" ")}>
            <div className={[classes.secondInfo, ""].join(" ")}>
              <h2 className={[classes.secondInfoTitle, "font-48"].join(" ")}>
                {t('tools.platform.title')}
              </h2>
              <h3 className={[classes.secondInfoSubtitle, "font-32"].join(" ")}>
                {t('tools.platform.subtitle')}
              </h3>
              <h4
                className={[classes.secondInfoSecondSubtitle, "font-24"].join(
                  " "
                )}
              >
                {t('tools.platform.text_01')}
              </h4>
              <p className={[classes.secondInfoText, "font-20"].join(" ")}>
                {t('tools.platform.text_02')}
              </p>
              <p className={[classes.secondInfoText, "font-20"].join(" ")}>
                {t('tools.platform.text_03')}
              </p>
              <p className={[classes.secondInfoText, "font-20"].join(" ")}>
                {t('tools.platform.text_04')}
              </p>
              <p className={[classes.secondInfoText, "font-20"].join(" ")}>
                {t('tools.platform.text_05')}
              </p>
            </div>
            {width > 1220 && (
              <>
                <div
                  className={[classes.secondPhone, ""].join(" ")}
                  ref={phoneRef}
                ></div>
                <div
                  className={[classes.secondCards, ""].join(" ")}
                  ref={cardsRef}
                ></div>
                <div
                  className={[classes.secondMonets, ""].join(" ")}
                  ref={monetsRef}
                ></div>
              </>
            )}
            {width < 1221 && (
              <div className={[classes.mobImg, ""].join(" ")}>
                <img src={img1} alt='' />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
