import Button from "../../GlobalComponents/Button/Button";
import React, { useRef, useEffect } from "react";
import classes from './WelcomeBonus.module.scss';
import welcome_bonus from '../../../assets/img/BonusesPage/welcome_bonus.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const WelcomeBonus = () => {
    const { t } = useTranslation();
    const bonusesWelcomeBodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.fromTo(bonusesWelcomeBodyRef.current, {
                    y: 100,
                    opacity: 0,
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    delay: 0.8,
                });
            }
        }
    }, []);
    return (
        <section className="container">
            <div ref={bonusesWelcomeBodyRef} className={classes.welcomeBonus}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('bonuses.welcome.title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('bonuses.welcome.text')}
                </p>
                <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                    {t('bonuses.welcome.subtitle')}
                </h3>
                <div className={[classes.list, 'font-20'].join(' ')}>
                    <ul>
                        <li>{t('bonuses.welcome.li_01')}</li>
                        <li>{t('bonuses.welcome.li_02')}</li>
                        <li>{t('bonuses.welcome.li_03')}</li>
                        <li>{t('bonuses.welcome.li_04')}</li>
                    </ul>
                    <ul>
                        <li>{t('bonuses.welcome.li_05')}</li>
                        <li>{t('bonuses.welcome.li_06')}</li>
                        <li>{t('bonuses.welcome.li_07')}</li>
                        <li>{t('bonuses.welcome.li_08')}.</li>
                    </ul>
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t('btn.get_bonus')}
                    </Button>
                </div>
                <img className={classes.image} src={welcome_bonus} alt='Welcome bonus'/>
            </div>
        </section>
    );
};

export default WelcomeBonus;
