import React, { useEffect, useRef } from "react";
import classes from './TermsOf.module.scss';
import coin_01 from '../../../assets/img/BonusesPage/TermsOf/coin_01.png';
import coin_02 from '../../../assets/img/BonusesPage/TermsOf/coin_02.png';
import coin_03 from '../../../assets/img/BonusesPage/TermsOf/coin_03.png';
import card from '../../../assets/img/BonusesPage/TermsOf/card.png';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const TermsOf = () => {
    const { t } = useTranslation();
    const termsOfImagesRef = useRef(null);
    const termsOfCardRef = useRef(null);
    const termsOfCoinRef_01 = useRef(null);
    const termsOfCoinRef_02 = useRef(null);
    const termsOfCoinRef_03 = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().to(termsOfCoinRef_01.current, {
                    x: 0,
                    y: 0,
                    scale: 1,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: termsOfImagesRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(termsOfCoinRef_02.current, {
                    x: 0,
                    y: 0,
                    scale: 1,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: termsOfImagesRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().to(termsOfCoinRef_03.current, {
                    x: 0,
                    y: 0,
                    scale: 1,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: termsOfImagesRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(termsOfCardRef.current, {
                    opacity: 0,
                    scale: 0.7,
                }, {
                    opacity: 1,
                    scale: 1,
                    scrollTrigger: {
                        trigger: termsOfImagesRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={classes.termsOf}>
            <div className="container">
                <div className={classes.termsOfBody}>
                    <div ref={termsOfImagesRef} className={classes.images}>
                        <img ref={termsOfCardRef} className={classes.card} src={card} alt=''/>
                        <img ref={termsOfCoinRef_01} className={[classes.coin, classes.coin_01].join(' ')} src={coin_01} alt=''/>
                        <img ref={termsOfCoinRef_02} className={[classes.coin, classes.coin_02].join(' ')} src={coin_02} alt=''/>
                        <img ref={termsOfCoinRef_03} className={[classes.coin, classes.coin_03].join(' ')} src={coin_03} alt=''/>
                    </div>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-48'].join(' ')}>
                            {t('bonuses.terms.title')}
                        </h2>
                        <ul className="font-20">
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_01')}
                                </Trans>
                            </li>
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_02')}
                                </Trans>
                            </li>
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_03')}
                                </Trans>
                            </li>
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_04')}
                                </Trans>
                            </li>
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_05')}
                                </Trans>
                            </li>
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_06')}
                                </Trans>
                            </li>
                            <li>
                                <Trans>
                                    {t('bonuses.terms.li_07')}
                                </Trans>
                            </li>
                        </ul>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.get_bonus')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsOf;
