import React, { useEffect, useRef, useState } from "react";
import classes from './BenefitsOf.module.scss';
import image_01 from '../../../assets/img/MainPage/BenefitsOf/image_01.png';
import image_02 from '../../../assets/img/MainPage/BenefitsOf/image_02.png';
import image_03 from '../../../assets/img/MainPage/BenefitsOf/image_03.png';
import image_04 from '../../../assets/img/MainPage/BenefitsOf/image_04.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const BenefitsOf = () => {
    const { t } = useTranslation();
    const benefitsCardRef_01 = useRef();
    const benefitsCardRef_02 = useRef(null);
    const benefitsCardRef_03 = useRef(null);
    const benefitsCardRef_04 = useRef(null);
    const benefitsCardsWrRef = useRef();
    const [domLoaded, setDomLoaded] = useState(false);
    useEffect(() => {
        if (domLoaded && document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(benefitsCardRef_01.current, {
                x: -100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: benefitsCardRef_01.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(benefitsCardRef_02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: benefitsCardsWrRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(benefitsCardRef_03.current, {
                x: 100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: benefitsCardRef_03.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(benefitsCardRef_04.current, {
                x: 200,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: benefitsCardRef_04.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, [domLoaded]);
    useEffect(() => {
        setDomLoaded(true);
    }, [])
    return (
        <section className="container">
            <div className={classes.benefitsOf}>
                <h2 className="font-48">
                    {t('home.benefits.title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('home.benefits.text')}
                </p>
                <div ref={benefitsCardsWrRef} className={classes.cards}>
                    <div id="benefitsCardRef_01" ref={benefitsCardRef_01} className={[classes.card, classes.card_01].join(' ')}>
                        <div className={classes.cardImage}>
                            <img src={image_01} alt='' />
                        </div>
                        <div className={classes.cardContent}>
                            <h3 className={[classes.cardContentTitle, 'font-32'].join(' ')}>
                                {t('home.benefits.card_01.title')}
                            </h3>
                            <p className={[classes.cardContentText, 'font-20'].join(' ')}>
                                {t('home.benefits.card_01.text')}
                            </p>
                        </div>
                    </div>
                    <div ref={benefitsCardRef_02} className={[classes.card, classes.card_02].join(' ')}>
                        <div className={classes.cardImage}>
                            <img src={image_02} alt='' />
                        </div>
                        <div className={classes.cardContent}>
                            <h3 className={[classes.cardContentTitle, 'font-32'].join(' ')}>
                                {t('home.benefits.card_02.title')}
                            </h3>
                            <p className={[classes.cardContentText, 'font-20'].join(' ')}>
                                {t('home.benefits.card_02.text')}
                            </p>
                        </div>
                    </div>
                    <div ref={benefitsCardRef_03} className={[classes.card, classes.card_03].join(' ')}>
                        <div className={classes.cardImage}>
                            <img src={image_03} alt='' />
                        </div>
                        <div className={classes.cardContent}>
                            <h3 className={[classes.cardContentTitle, 'font-32'].join(' ')}>
                                {t('home.benefits.card_03.title')}
                            </h3>
                            <p className={[classes.cardContentText, 'font-20'].join(' ')}>
                                {t('home.benefits.card_03.text')}
                            </p>
                        </div>
                    </div>
                    <div ref={benefitsCardRef_04} className={[classes.card, classes.card_04].join(' ')}>
                        <div className={classes.cardImage}>
                            <img src={image_04} alt='' />
                        </div>
                        <div className={classes.cardContent}>
                            <h3 className={[classes.cardContentTitle, 'font-32'].join(' ')}>
                                {t('home.benefits.card_04.title')}
                            </h3>
                            <p className={[classes.cardContentText, 'font-20'].join(' ')}>
                                {t('home.benefits.card_04.text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BenefitsOf;
