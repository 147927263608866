import React from "react";
import classes from "./Button.module.scss";

const Button = ({ children, login, customLink }) => {
  if (customLink) {
    return (
      <a
        href={customLink}
        className={classes.button}
      >
        <span>{children}</span>
      </a>
    )
  }
  return (
    <a
      href={`${process.env.REACT_APP_CABINET_LINK}/${login ? 'login' : 'signup'}`}
      className={classes.button}
    >
      <span>{children}</span>
    </a>
  );
};

export default Button;
