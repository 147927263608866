import Button from "../../GlobalComponents/Button/Button";
import React, { useEffect, useRef, useState } from "react";
import classes from './Banner.module.scss';
import money_01 from '../../../assets/img/MainPage/Banner/money_01.png';
import money_02 from '../../../assets/img/MainPage/Banner/money_02.png';
import money_03 from '../../../assets/img/MainPage/Banner/money_03.png';
import money_04 from '../../../assets/img/MainPage/Banner/money_04.png';
import money_05 from '../../../assets/img/MainPage/Banner/money_05.png';
import money_06 from '../../../assets/img/MainPage/Banner/money_06.png';
import bg from '../../../assets/img/MainPage/Banner/bg.png';
import bg_mob from '../../../assets/img/MainPage/Banner/bg_mob.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { Trans, useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
    const { t } = useTranslation();
    const mainBannerRef = useRef(null);
    const bannerBodyRef = useRef(null);
    const bannerBgRef = useRef(null);
    const moneyRef_01 = useRef(null);
    const moneyRef_02 = useRef(null);
    const moneyRef_03 = useRef(null);
    const moneyRef_04 = useRef(null);
    const moneyRef_05 = useRef(null);
    const moneyRef_06 = useRef(null);
    const moneyRefWr_01 = useRef(null);
    const moneyRefWr_02 = useRef(null);
    const moneyRefWr_03 = useRef(null);
    const moneyRefWr_04 = useRef(null);
    const moneyRefWr_05 = useRef(null);
    const moneyRefWr_06 = useRef(null);
    const [width, setWidth] = useState();
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", (event) => {
            setWidth(window.innerWidth);
        });
    }, []);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.fromTo(bannerBodyRef.current, {
                    y: 100,
                    opacity: 0,
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.fromTo(bannerBgRef.current, {
                    scale: 1.2,
                }, {
                    scale: 1,
                    duration: 1.5,
                    delay: 0,
                });
                // money
                gsap.to(moneyRef_01.current, {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(moneyRef_02.current, {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(moneyRef_03.current, {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(moneyRef_04.current, {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(moneyRef_05.current, {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    delay: 0.5,
                });
                gsap.to(moneyRef_06.current, {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0,
                    duration: 1,
                    delay: 0.5,
                });
                // scroll money
                gsap.timeline().to(moneyRefWr_01.current, {
                    y: -50,
                    x: 100,
                    opacity: 0,
                    scale: 1.1,
                    scrollTrigger: {
                        trigger: mainBannerRef.current,
                        start: "top top",
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().to(moneyRefWr_02.current, {
                    y: 50,
                    x: -100,
                    opacity: 0,
                    scale: 1.1,
                    scrollTrigger: {
                        trigger: mainBannerRef.current,
                        start: "top top",
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().to(moneyRefWr_03.current, {
                    y: 100,
                    x: -60,
                    opacity: 0,
                    scale: 1.1,
                    scrollTrigger: {
                        trigger: mainBannerRef.current,
                        start: "top top",
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().to(moneyRefWr_04.current, {
                    y: 140,
                    x: 100,
                    opacity: 0,
                    scale: 1.1,
                    scrollTrigger: {
                        trigger: mainBannerRef.current,
                        start: "top top",
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().to(moneyRefWr_05.current, {
                    y: 140,
                    x: -100,
                    opacity: 0,
                    scale: 1.1,
                    scrollTrigger: {
                        trigger: mainBannerRef.current,
                        start: "top top",
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().to(moneyRefWr_06.current, {
                    y: 140,
                    x: -50,
                    opacity: 0,
                    scale: 1.1,
                    scrollTrigger: {
                        trigger: mainBannerRef.current,
                        start: "top top",
                        end: "+=400px",
                        scrub: 2,
                    }
                });
            }
        }
    }, []);
    return (
        <section ref={mainBannerRef} className={classes.banner}>
            <div className={classes.bgWr}>
                <img
                    className={classes.bg}
                    src={width > 480 ? bg : bg_mob}
                    ref={bannerBgRef}
                    alt=''
                />
            </div>
            <div className="container">
                <div className={classes.bannerBody}>
                    <div ref={bannerBodyRef}>
                        <h1 className={[classes.title, 'font-64'].join(' ')}>
                            <Trans>
                                {t('home.banner.title')}
                            </Trans>
                        </h1>
                        <div className={[classes.text, 'font-20'].join(' ')}>
                            <p>
                                {t('home.banner.text_01')}
                            </p>
                            <p>
                                {t('home.banner.text_02')}
                            </p> 
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.start_trading')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div ref={moneyRefWr_01} className={classes.moneyWr}>
                    <img ref={moneyRef_01} className={[classes.money, classes.money_01].join(' ')} src={money_01} alt='' />
                </div>
                <div ref={moneyRefWr_02} className={classes.moneyWr}>
                    <img ref={moneyRef_02} className={[classes.money, classes.money_02].join(' ')} src={money_02} alt='' />
                </div>
                <div ref={moneyRefWr_03} className={classes.moneyWr}>
                    <img ref={moneyRef_03} className={[classes.money, classes.money_03].join(' ')} src={money_03} alt='' />
                </div>
                <div ref={moneyRefWr_04} className={classes.moneyWr}>
                    <img ref={moneyRef_04} className={[classes.money, classes.money_04].join(' ')} src={money_04} alt='' />
                </div>
                <div ref={moneyRefWr_05} className={classes.moneyWr}>
                    <img ref={moneyRef_05} className={[classes.money, classes.money_05].join(' ')} src={money_05} alt='' />
                </div>
                <div ref={moneyRefWr_06} className={classes.moneyWr}>
                    <img ref={moneyRef_06} className={[classes.money, classes.money_06].join(' ')} src={money_06} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;
