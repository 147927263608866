import React, { useEffect, useState } from "react";
import classes from './Tabs.module.scss';
import icon_01 from '../../../assets/img/MainPage/Tabs/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/Tabs/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/Tabs/icon_03.png';
import flag_01 from '../../../assets/img/MainPage/Tabs/flag_01.png';
import flag_02 from '../../../assets/img/MainPage/Tabs/flag_02.png';
import flag_03 from '../../../assets/img/MainPage/Tabs/flag_03.png';
import flag_04 from '../../../assets/img/MainPage/Tabs/flag_04.png';
import com_01 from '../../../assets/img/MainPage/Tabs/com_01.png';
import com_02 from '../../../assets/img/MainPage/Tabs/com_02.png';
import com_03 from '../../../assets/img/MainPage/Tabs/com_03.png';
import com_04 from '../../../assets/img/MainPage/Tabs/com_04.png';
import bg from '../../../assets/img/MainPage/Tabs/bg.png';
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";

const buttons = [
    {
        id: 1,
        text: 'home.tabs.title_01',
    },
    {
        id: 2,
        text: 'home.tabs.title_02',
    },
    {
        id: 3,
        text: 'home.tabs.title_03',
    },
]

const tabs = {
    1: {
        title: 'home.tab_01.title',
        text: 'home.tab_01.text',
        cards: [
            {
                icon: icon_01,
                text: 'home.tab_01.card_01.text',
            },
            {
                icon: icon_03,
                text: 'home.tab_01.card_02.text',
            },
            {
                icon: icon_02,
                text: 'home.tab_01.card_03.text',
            },
        ],
    },
    2: {
        title: 'home.tab_02.title',
        text: 'home.tab_02.text',
        table: {
            title: 'home.tab_02.table.title',
            rows: [
                {
                    col_01_text: 'US Dollar',
                    col_01_icon: flag_01,
                    col_02_text: 'Euro',
                    col_02_icon: flag_02,
                },
                {
                    col_01_text: 'Japanese Yen',
                    col_01_icon: flag_03,
                    col_02_text: 'British Pound',
                    col_02_icon: flag_04,
                },
            ]
        },
    },
    3: {
        title: 'home.tab_03.title',
        text: 'home.tab_03.text',
        table: {
            title: 'home.tab_03.table.title',
            rows: [
                {
                    col_01_text: 'GOLD',
                    col_01_icon: com_01,
                    col_02_text: 'SILVER',
                    col_02_icon: com_02,
                },
                {
                    col_01_text: 'COPPER',
                    col_01_icon: com_03,
                    col_02_text: 'PLATINUM',
                    col_02_icon: com_04,
                },
            ]
        },
    },
}

const Tabs = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    const [ domLoaded, setDomLoaded ] = useState(false);
    useEffect(() => {
        setDomLoaded(true);
    }, []);
    return (
        <>
            {domLoaded &&
                <section className={classes.tabs}>
                    <div className={["container", classes.bgContainer].join(' ')}>
                        <img className={classes.bg} src={bg} alt=''/>
                    </div>
                    <div className="container">
                        <div className={classes.buttons}>
                            {buttons.map((button) =>
                                <div 
                                    key={button.id} 
                                    className={`${classes.button} ${currentTab === button.id && classes.buttonActive}`}
                                    onClick={() => setCurrentTab(button.id)}
                                >
                                    {t(button.text)}
                                </div>
                            )}
                        </div>
                        <div className={classes.content}>
                            <h3 className={[classes.contentTitle, 'font-20'].join(' ')}>
                                {t(tabs[currentTab].title)}
                            </h3>
                            <p className={[classes.contentText, 'font-20'].join(' ')}>
                                {t(tabs[currentTab].text)}
                            </p>
                        </div>
                    </div>
                    {tabs[currentTab]?.cards &&
                        <div className={["container", classes.cardsContainer].join(' ')}>
                            <div className={classes.cards}>
                                {tabs[currentTab]?.cards.map((card, cardIndex) =>
                                    <div key={cardIndex} className={classes.card}>
                                        <img src={card?.icon} alt=''/>
                                        <div className={[classes.cardText, 'font-20'].join(' ')}>
                                            <Trans components={{span: <span></span>}}>
                                                {t(card?.text)}
                                            </Trans>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {tabs[currentTab].table &&
                        <div className="container">
                            <div className={classes.tableWr}>
                                <p className={[classes.tableText, 'font-20'].join(' ')}>
                                    {t(tabs[currentTab].table.title)}
                                </p>
                                <table className="font-20">
                                    <tbody>
                                        {tabs[currentTab].table.rows.map((row, rowIndex) =>
                                            <tr key={rowIndex}>
                                                <td>
                                                    <div className={classes.tdBody}>
                                                        <span>{t(row.col_01_text)}</span>
                                                        <img src={row.col_01_icon} alt=''/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={classes.tdBody}>
                                                        <span>{t(row.col_02_text)}</span>
                                                        <img src={row.col_02_icon} alt=''/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <div className="container">
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.start_trading')}
                            </Button>
                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default Tabs;
