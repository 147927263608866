import React from "react";
import { Helmet } from "react-helmet-async";
import First from "../../components/ToolsComponents/First/First";
import Second from "../../components/ToolsComponents/Second/Second";
import Four from "../../components/ToolsComponents/Four/Four";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import ChooseAccount from "../../components/AccountTypes/ChooseAccount/ChooseAccount";

const ToolsPage = () => {
  return (
    <>
      <Helmet>
        <title>GMG (Dubai) Limited · Tools & Platforms</title>
        <meta name="title" content="GMG (Dubai) Limited - Trading Tools and Platforms for Maximum Profitability" />
        <meta property="og:title" content="GMG (Dubai) Limited - Trading Tools and Platforms for Maximum Profitability" />
        <meta property="twitter:title" content="GMG (Dubai) Limited - Trading Tools and Platforms for Maximum Profitability" />

        <meta name="description" content="Take your trading to the next level with GMG (Dubai) Limited's comprehensive suite of tools and platforms designed to help you make the most of your investment: advanced charting tools, real-time market data, and customizable trading interfaces. Sign up today!" />
        <meta property="og:description" content="Take your trading to the next level with GMG (Dubai) Limited's comprehensive suite of tools and platforms designed to help you make the most of your investment: advanced charting tools, real-time market data, and customizable trading interfaces. Sign up today!" />
        <meta property="twitter:description" content="Take your trading to the next level with GMG (Dubai) Limited's comprehensive suite of tools and platforms designed to help you make the most of your investment: advanced charting tools, real-time market data, and customizable trading interfaces. Sign up today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <ChooseAccount />
        <Four />
        <Footer />
      </div>
    </>
  );
};

export default ToolsPage;
