import React, { useEffect, useRef } from "react";
import classes from './OurTools.module.scss';
import icon_01 from '../../../assets/img/MainPage/OurTools/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/OurTools/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/OurTools/icon_03.png';
import icon_04 from '../../../assets/img/MainPage/OurTools/icon_04.png';
import icon_05 from '../../../assets/img/MainPage/OurTools/icon_05.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);


const OurTools = () => {
    const { t } = useTranslation();
    const ourToolsCardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cardRef_05 = useRef(null);
    const cards = [
        {
            icon: icon_01,
            ref: cardRef_01,
            title: 'home.our_tools.card_01.title',
            text: 'home.our_tools.card_01.text',
        },
        {
            icon: icon_02,
            ref: cardRef_02,
            title: 'home.our_tools.card_02.title',
            text: 'home.our_tools.card_02.text',
        },
        {
            icon: icon_03,
            ref: cardRef_03,
            title: 'home.our_tools.card_03.title',
            text: 'home.our_tools.card_03.text',
        },
        {
            icon: icon_04,
            ref: cardRef_04,
            title: 'home.our_tools.card_04.title',
            text: 'home.our_tools.card_04.text',
        },
        {
            icon: icon_05,
            ref: cardRef_05,
            title: 'home.our_tools.card_05.title',
            text: 'home.our_tools.card_05.text',
        },
    ];
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(cardRef_01.current, {
                    y: 50,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: ourToolsCardsRef.current,
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(cardRef_02.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: ourToolsCardsRef.current,
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(cardRef_03.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: ourToolsCardsRef.current,
                        end: "+=400px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(cardRef_04.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: ourToolsCardsRef.current,
                        end: "+=800px",
                        scrub: 2,
                    }
                });
                gsap.timeline().fromTo(cardRef_05.current, {
                    y: 250,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: ourToolsCardsRef.current,
                        end: "+=800px",
                        scrub: 2,
                    }
                });
            }
        }
    }, []);
    return (
        <section className={classes.ourTools}>
            <div className="container">
                <h2 className="font-48">
                    {t('home.our_tools.title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('home.our_tools.text')}
                </p>
                <div ref={ourToolsCardsRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <h3 className={[classes.cardTitle, 'font-32'].join(' ')}>
                                {t(card.title)}
                            </h3>
                            <p className={[classes.cardText, 'font-20'].join(' ')}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.swiperWr}>
                <Swiper 
                    modules={[Pagination]}
                    className="ourToolsSwiper"
                    spaceBetween={20}
                    slidesPerView={1}
                    pagination={true}
                >
                    {cards.map((card, index) =>
                        <SwiperSlide key={index} className="ourToolsSwiperSlide">
                            <div className={classes.card}>
                                <img className={classes.cardIcon} src={card.icon} alt=''/>
                                <h3 className={[classes.cardTitle, 'font-32'].join(' ')}>
                                    {t(card.title)}
                                </h3>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    {t(card.text)}
                                </p>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </section>
    );
};

export default OurTools;
