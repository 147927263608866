import React from "react";
import classes from './Webinars.module.scss';
import { useTranslation } from "react-i18next";
import preview from '../../../assets/img/EducationPage/video_preview.png';

const videos = [
    {
        title: 'education_vid_title_01',
        name: '01_nft_vid.mp4',
    },
    {
        title: 'education_vid_title_02',
        name: '02_consumer_price_index.mp4',
    },
    {
        title: '#3',
        name: '03_stock_exchange_panorama.mp4',
    },
    {
        title: '#4',
        name: '04_stock_exchange_panorama.mp4',
    },
];

const Webinars = () => {
    const { t } = useTranslation();
    const serverDomain = 'https://storage.devindex.net/';
    return (
        <section className="container">
            <div className={classes.webinars}>
                <h2 className={`${classes.title} font-48`}>
                    {t('education_title_03')}
                </h2>
                <div className={classes.content}>
                    {videos.map((video, index) =>
                        <div key={index} className={classes.videoWrap}>
                            <h4 className={classes.videoTitle}>
                                {t(video.title)}
                            </h4>
                            <video 
                                controls
                                preload="metadata"
                                controlsList="nodownload"
                                poster={preview}
                                className={classes.video}
                            >
                                <source src={`${serverDomain}/webinars/${video.name}`} type="video/mp4" />
                            </video>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Webinars;
