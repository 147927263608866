import Button from "../../GlobalComponents/Button/Button";
import React, { useEffect, useRef, useState } from "react";
import img1 from "../../../assets/img/tools/3/1.png";
import img2 from "../../../assets/img/tools/3/2.png";
import img3 from "../../../assets/img/tools/3/3.png";
import img4 from "../../../assets/img/tools/3/4.png";
import classes from "./Four.module.scss";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Four = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const mainRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(ref1.current, {
        x: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(ref2.current, {
        x: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(ref3.current, {
        x: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
      gsap.timeline().to(ref4.current, {
        x: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
      });
    }
  }, [width]);
  return (
    <div className={[classes.four, ""].join(" ")} ref={mainRef}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.fourTitle, "font-48"].join(" ")}>
            {t('tools.start.title')}
          </h2>

          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fourRowColumnItemImg, ""].join(" ")}
                  ref={ref1}
                >
                  <img src={img1} alt=''/>
                </div>
                <p
                  className={[classes.fourRowColumnItemText, "font-24"].join(
                    " "
                  )}
                >
                  {t('tools.start.text_01')}
                </p>
              </div>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fourRowColumnItemImg, ""].join(" ")}
                  ref={ref2}
                >
                  <img src={img2} alt=''/>
                </div>
                <p
                  className={[classes.fourRowColumnItemText, "font-24"].join(
                    " "
                  )}
                >
                  {t('tools.start.text_02')}
                </p>
              </div>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fourRowColumnItemImg, ""].join(" ")}
                  ref={ref3}
                >
                  <img src={img3} alt=''/>
                </div>
                <p
                  className={[classes.fourRowColumnItemText, "font-24"].join(
                    " "
                  )}
                >
                  {t('tools.start.text_03')}
                </p>
              </div>
            </div>
            <div className={[classes.fourRowColumn, ""].join(" ")}>
              <div className={[classes.fourRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fourRowColumnItemImg, ""].join(" ")}
                  ref={ref4}
                >
                  <img src={img4} alt=''/>
                </div>
                <p
                  className={[classes.fourRowColumnItemText, "font-24"].join(
                    " "
                  )}
                >
                  {t('tools.start.text_04')}
                </p>
              </div>
            </div>
          </div>
          <div className={[classes.fourBtn, ""].join(" ")}>
            <Button>
              {t('btn.start_trading')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
