import React, { useRef } from "react";
import classes from './ChooseAccount.module.scss';
import card_bg_01 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_01.png';
import card_bg_02 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_02.png';
import card_bg_03 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_03.png';
import card_bg_04 from '../../../assets/img/AccountTypes/ChooseAccount/card_bg_04.png';
import arrow from '../../../assets/img/AccountTypes/ChooseAccount/arrow.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const cards = [
    {
        id: 1,
        image: card_bg_01,
        title: 'Standard',
        desc: 'tools.choose.card_01.desc',
        list: [
            'tools.choose.card_01.li_01',
            'tools.choose.card_01.li_02',
        ],
        text: 'tools.choose.card_01.text',
    },
    {
        id: 2,
        image: card_bg_02,
        title: 'Fixed',
        desc: 'tools.choose.card_02.desc',
        list: [
            'tools.choose.card_02.li_01',
            'tools.choose.card_02.li_02',
        ],
        text: 'tools.choose.card_02.text',
    },
    {
        id: 3,
        image: card_bg_03,
        title: 'ECN',
        desc: 'tools.choose.card_03.desc',
        list: [
            'tools.choose.card_03.li_01',
            'tools.choose.card_03.li_02',
        ],
        text: 'tools.choose.card_03.text',
    },
    {
        id: 4,
        image: card_bg_04,
        title: 'Crypto',
        desc: 'tools.choose.card_04.desc',
        list: [
            'tools.choose.card_04.li_01',
            'tools.choose.card_04.li_02',
        ],
        text: 'tools.choose.card_04.text',
    },
];

const ChooseAccount = () => {
    const { t } = useTranslation();
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    return (
        <section className={classes.chooseAccount}>
            <div className="container">
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    {t('tools.choose.title')}
                </h2>
            </div>
            <div className={classes.swiperWr}>
                <Swiper 
                    modules={[Pagination, Navigation]}
                    className="chooseAccountTypeSwiper"
                    spaceBetween={20}
                    slidesPerView={"auto"}
                    pagination={true}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                    }}
                >
                    {cards.map((card) =>
                        <SwiperSlide key={card.id} className="chooseAccountTypeSwiperSlide">
                            <div className={classes.card}>
                                <div className={classes.cardContent}>
                                    <div className={classes.cardDesc}>
                                        <span>
                                            {t(card.desc)}
                                        </span>
                                    </div>
                                    <h3 className={[classes.cardTitle, 'font-32'].join(' ')}>
                                        {card.title}
                                    </h3>
                                    <ul className="font-20">
                                        {card.list.map((li, liIndex) =>
                                            <li key={liIndex}>
                                                {t(li)}
                                            </li>
                                        )}
                                    </ul>
                                    <p className={[classes.cardText, 'font-20'].join(' ')}>
                                        {t(card.text)}
                                    </p>
                                </div>
                                <div className={classes.cardBtn}>
                                    <a href={`${process.env.REACT_APP_CABINET_LINK}/signup`} className={[classes.btn, 'no-select'].join(' ')}>
                                        {t('btn.open_account')}
                                    </a>
                                </div>
                                <img className={classes.cardImg} src={card.image} alt=''/>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            <div className="container">
                <div className={classes.pagination}>
                    <img ref={prevRef} className={classes.pagPrev} src={arrow} alt=''/>
                    <img ref={nextRef} className={classes.pagNext} src={arrow} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default ChooseAccount;