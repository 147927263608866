import { Helmet } from "react-helmet-async";
import Banner from '../../components/HomePage/Banner/Banner';
import OurTools from '../../components/HomePage/OurTools/OurTools';
import Tabs from '../../components/HomePage/Tabs/Tabs';
import BenefitsOf from '../../components/HomePage/BenefitsOf/BenefitsOf';
import BecomeBetter from '../../components/HomePage/BecomeBetter/BecomeBetter';
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>GMG (Dubai) Limited</title>
        <meta name="title" content="GMG (Dubai) Limited - A Secure and User-Friendly Trading Platform for Cryptocurrencies" />
        <meta property="og:title" content="GMG (Dubai) Limited - A Secure and User-Friendly Trading Platform for Cryptocurrencies" />
        <meta property="twitter:title" content="GMG (Dubai) Limited - A Secure and User-Friendly Trading Platform for Cryptocurrencies" />

        <meta name="description" content="Join GMG (Dubai) Limited, the leading trading platform for buying and selling cryptocurrencies in a safe and easy-to-use environment. Enjoy competitive fees, fast transaction speeds, and reliable customer support. Sign up now and start trading today!" />
        <meta property="og:description" content="Join GMG (Dubai) Limited, the leading trading platform for buying and selling cryptocurrencies in a safe and easy-to-use environment. Enjoy competitive fees, fast transaction speeds, and reliable customer support. Sign up now and start trading today!" />
        <meta property="twitter:description" content="Join GMG (Dubai) Limited, the leading trading platform for buying and selling cryptocurrencies in a safe and easy-to-use environment. Enjoy competitive fees, fast transaction speeds, and reliable customer support. Sign up now and start trading today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <OurTools />
        <Tabs />
        <BenefitsOf />
        <BecomeBetter />
        <Footer />
      </div>
    </>
  );
};

export default Homepage;
