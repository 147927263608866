import React from "react";
import { Helmet } from "react-helmet-async";
import Banner from "../../components/BonusesPage/Banner/Banner";
import WelcomeBonus from "../../components/BonusesPage/WelcomeBonus/WelcomeBonus";
import TermsOf from "../../components/BonusesPage/TermsOf/TermsOf";
import GetDeposit from "../../components/BonusesPage/GetDeposit/GetDeposit";
import HowTo from "../../components/BonusesPage/HowTo/HowTo";
import WhatTypes from "../../components/BonusesPage/WhatTypes/WhatTypes";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';

const BonusesPage = () => {
    return (
        <>
            <Helmet>
                <title>GMG (Dubai) Limited · Bonuses</title>
                <meta name="title" content="GMG (Dubai) Limited - Get More Out of Your Trading with our Bonus Programs" />
                <meta property="og:title" content="GMG (Dubai) Limited - Get More Out of Your Trading with our Bonus Programs" />
                <meta property="twitter:title" content="GMG (Dubai) Limited - Get More Out of Your Trading with our Bonus Programs" />

                <meta name="description" content="Boost your trading potential with GMG (Dubai) Limited's generous bonus programs. We offer a range of bonuses, including deposit bonuses, trading rebates, and referral bonuses, to help you maximize your profitability. Take advantage of our exciting bonus programs!" />
                <meta property="og:description" content="Boost your trading potential with GMG (Dubai) Limited's generous bonus programs. We offer a range of bonuses, including deposit bonuses, trading rebates, and referral bonuses, to help you maximize your profitability. Take advantage of our exciting bonus programs!" />
                <meta property="twitter:description" content="Boost your trading potential with GMG (Dubai) Limited's generous bonus programs. We offer a range of bonuses, including deposit bonuses, trading rebates, and referral bonuses, to help you maximize your profitability. Take advantage of our exciting bonus programs!" />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <WelcomeBonus />
                <TermsOf />
                <GetDeposit />
                <HowTo />
                <WhatTypes />
                <Footer />
            </div>
        </>
    );
};

export default BonusesPage;
