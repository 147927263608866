import React from "react";
import { Helmet } from "react-helmet-async";
import Banner from "../../components/AccountTypes/Banner/Banner";
import AccountTypes from "../../components/AccountTypes/AccountTypes/AccountTypes";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';

const AccountsPage = () => {
    return (
        <>
            <Helmet>
                <title>GMG (Dubai) Limited · Account Types</title>
                <meta name="title" content="GMG (Dubai) Limited - Choose the Right Account Type for Your Trading Needs" />
                <meta property="og:title" content="GMG (Dubai) Limited - Choose the Right Account Type for Your Trading Needs" />
                <meta property="twitter:title" content="GMG (Dubai) Limited - Choose the Right Account Type for Your Trading Needs" />

                <meta name="description" content="At GMG (Dubai) Limited, we offer a range of account types to suit different trading styles and investment objectives. Whether you're a beginner or a seasoned trader, our account options give you access to the features and benefits you need to succeed." />
                <meta property="og:description" content="At GMG (Dubai) Limited, we offer a range of account types to suit different trading styles and investment objectives. Whether you're a beginner or a seasoned trader, our account options give you access to the features and benefits you need to succeed." />
                <meta property="twitter:description" content="At GMG (Dubai) Limited, we offer a range of account types to suit different trading styles and investment objectives. Whether you're a beginner or a seasoned trader, our account options give you access to the features and benefits you need to succeed." />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <AccountTypes />
                <Footer />
            </div>
        </>
    );
};

export default AccountsPage;
