import React, { useEffect, useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./First.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../../assets/img/tools/1/1.png";
import img2 from "../../../assets/img/tools/1/2.png";
import img3 from "../../../assets/img/tools/1/3.png";
import img4 from "../../../assets/img/tools/1/4.png";
import img5 from "../../../assets/img/tools/1/5.png";
import bg from '../../../assets/img/tools/1/bg.png';
// Import Swiper styles
import "swiper/css";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const [width, setWidth] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.first, ""].join(" ")}>

      <div className={[classes.firstBody, ""].join(" ")}>
        <img
          className={classes.bannerBg}
          src={bg}
          alt=""
        />
        <div className="container">
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <div className={[classes.firstTop, ""].join(" ")}>
              <h1 className={[classes.firstTopTitle, "font-64"].join(" ")}>
                {t('tools.banner.title')}
              </h1>

              {width > 1220 && (
                <h2 className={[classes.firstTopSubtitle, "font-32"].join(" ")}>
                  {t('tools.banner.subtitle')}
                </h2>
              )}
            </div>
            <div className={[classes.firstInfo, ""].join(" ")}>
              <div className={[classes.firstInfoLeft, ""].join(" ")}>
                <p className={[classes.firstInfoLeftText, "font-20"].join(" ")}>
                  {t('tools.banner.text')}
                </p>
                <div className={[classes.firstInfoLeftBtn, ""].join(" ")}>
                  <Button>
                    {t('btn.start_trading')}
                  </Button>
                </div>
              </div>
              {width > 1220 && (
                <div className={[classes.firstInfoRight, ""].join(" ")}>
                  <div className={[classes.firstInfoRightRow, ""].join(" ")}>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      12 {t('tools.banner.commodities')}
                    </p>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      44 FX
                    </p>
                  </div>
                  <div
                    className={[classes.firstInfoRightLine, ""].join(" ")}
                  ></div>

                  <div className={[classes.firstInfoRightRow, ""].join(" ")}>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      17 {t('tools.banner.cryptocurrencies')}
                    </p>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      44 FX
                    </p>
                  </div>
                </div>
              )}
            </div>

            {width < 1221 && (
              <div className={[classes.firstMobWrap, ""].join(" ")}>
                <h2 className={[classes.firstTopSubtitle, "font-32"].join(" ")}>
                  {t('tools.banner.subtitle')}
                </h2>
                <div className={[classes.firstInfoRight, ""].join(" ")}>
                  <div className={[classes.firstInfoRightRow, ""].join(" ")}>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      12 {t('tools.banner.commodities')}
                    </p>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      44 FX
                    </p>
                  </div>
                  <div
                    className={[classes.firstInfoRightLine, ""].join(" ")}
                  ></div>

                  <div className={[classes.firstInfoRightRow, ""].join(" ")}>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      17 {t('tools.banner.cryptocurrencies')}
                    </p>
                    <p
                      className={[
                        classes.firstInfoRightRowItem,
                        "font-20",
                      ].join(" ")}
                    >
                      44 FX
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {width > 1220 && (
            <div className={classes.firstBottom}>
              <div className={classes.firstBottomLeft}>
                <h2 className={[classes.firstBottomTitle, "font-48"].join(" ")}>
                  {t('tools.banner.our_tools')}
                </h2>
                <div className={classes.firstBottomLeftArrows}>
                  <div
                    className={classes.firstBottomLeftArrowsLeft}
                    onClick={() => my_swiper.slidePrev()}
                  ></div>
                  <div
                    className={classes.firstBottomLeftArrowsRight}
                    onClick={() => my_swiper.slideNext()}
                  ></div>
                </div>
              </div>

              <div className={classes.firstBottomSlider}>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={3}
                  onInit={(ev) => {
                    set_my_swiper(ev);
                  }}
                >
                  <SwiperSlide>
                    <div className={classes.firstBottomSliderSlide}>
                      <div
                        className={classes.firstBottomSliderSlideItem}
                      >
                        <div
                          className={classes.firstBottomSliderSlideItemImg}
                        >
                          <img src={img1} alt='' />
                        </div>
                        <div
                          className={[classes.firstBottomSliderSlideItemTitle, "font-32"].join(" ")}
                        >
                          {t('tools.banner.card_01.title')}
                        </div>
                        <div
                          className={[classes.firstBottomSliderSlideItemText, "font-20"].join(" ")}
                        >
                          {t('tools.banner.card_01.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className={[classes.firstBottomSliderSlide, ""].join(" ")}
                    >
                      <div className={classes.firstBottomSliderSlideItem}>
                        <div className={classes.firstBottomSliderSlideItemImg}>
                          <img src={img2} alt='' />
                        </div>
                        <div className={[classes.firstBottomSliderSlideItemTitle, "font-32"].join(" ")}>
                          {t('tools.banner.card_02.title')}
                        </div>
                        <div className={[classes.firstBottomSliderSlideItemText, "font-20"].join(" ")}>
                          {t('tools.banner.card_02.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className={[classes.firstBottomSliderSlide, ""].join(" ")}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItem,
                          "",
                        ].join(" ")}
                      >
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemImg,
                            "",
                          ].join(" ")}
                        >
                          <img src={img3} alt='' />
                        </div>
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('tools.banner.card_03.title')}
                        </div>
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('tools.banner.card_03.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className={[classes.firstBottomSliderSlide, ""].join(" ")}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItem,
                          "",
                        ].join(" ")}
                      >
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemImg,
                            "",
                          ].join(" ")}
                        >
                          <img src={img4} alt='' />
                        </div>
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('tools.banner.card_04.title')}
                        </div>
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('tools.banner.card_04.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className={[classes.firstBottomSliderSlide, ""].join(" ")}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItem,
                          "",
                        ].join(" ")}
                      >
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemImg,
                            "",
                          ].join(" ")}
                        >
                          <img src={img5} alt='' />
                        </div>
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemTitle,
                            "font-32",
                          ].join(" ")}
                        >
                          {t('tools.banner.card_05.title')}
                        </div>
                        <div
                          className={[
                            classes.firstBottomSliderSlideItemText,
                            "font-20",
                          ].join(" ")}
                        >
                          {t('tools.banner.card_05.text')}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={[classes.slideLast, ""].join(" ")}></div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
      {width < 1221 && (
        <div className={[classes.firstMob, ""].join(" ")}>
          <div className="container">
            <h2 className={[classes.firstBottomTitle, "font-48"].join(" ")}>
              {t('tools.banner.our_tools')}
            </h2>
            <div className={[classes.firstBottomSlider, ""].join(" ")}>
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                onInit={(ev) => {
                  set_my_swiper(ev);
                }}
                breakpoints={{
                  1100: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  100: {
                    slidesPerView: 1,
                  },
                }}
              >
                <SwiperSlide>
                  <div
                    className={[classes.firstBottomSliderSlide, ""].join(" ")}
                  >
                    <div
                      className={[classes.firstBottomSliderSlideItem, ""].join(
                        " "
                      )}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemImg,
                          "",
                        ].join(" ")}
                      >
                        <img src={img1} alt='' />
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemTitle,
                          "font-32",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_01.title')}
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_01.text')}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className={[classes.firstBottomSliderSlide, ""].join(" ")}
                  >
                    <div
                      className={[classes.firstBottomSliderSlideItem, ""].join(
                        " "
                      )}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemImg,
                          "",
                        ].join(" ")}
                      >
                        <img src={img2} alt='' />
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemTitle,
                          "font-32",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_02.title')}
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_02.text')}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className={[classes.firstBottomSliderSlide, ""].join(" ")}
                  >
                    <div
                      className={[classes.firstBottomSliderSlideItem, ""].join(
                        " "
                      )}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemImg,
                          "",
                        ].join(" ")}
                      >
                        <img src={img3} alt='' />
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemTitle,
                          "font-32",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_03.title')}
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_03.text')}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className={[classes.firstBottomSliderSlide, ""].join(" ")}
                  >
                    <div
                      className={[classes.firstBottomSliderSlideItem, ""].join(
                        " "
                      )}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemImg,
                          "",
                        ].join(" ")}
                      >
                        <img src={img4} alt='' />
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemTitle,
                          "font-32",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_04.title')}
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_04.text')}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className={[classes.firstBottomSliderSlide, ""].join(" ")}
                  >
                    <div
                      className={[classes.firstBottomSliderSlideItem, ""].join(
                        " "
                      )}
                    >
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemImg,
                          "",
                        ].join(" ")}
                      >
                        <img src={img5} alt='' />
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemTitle,
                          "font-32",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_05.title')}
                      </div>
                      <div
                        className={[
                          classes.firstBottomSliderSlideItemText,
                          "font-20",
                        ].join(" ")}
                      >
                        {t('tools.banner.card_05.text')}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className={classes.firstBottomLeftArrows}>
              <div
                className={classes.firstBottomLeftArrowsLeft}
                onClick={() => my_swiper.slidePrev()}
              ></div>
              <div
                className={classes.firstBottomLeftArrowsRight}
                onClick={() => my_swiper.slideNext()}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default First;
